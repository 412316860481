@import 'src/scss/functions';

$info-request-item-button-columns-xlarge: 9;
$info-request-item-button-columns-large: 6;
$info-request-item-button-padding: 16px;
$info-request-item-button-size: 102px;
$info-request-item-button-column-width-xlarge: getInfoRequestButtonGridWidth(
  $info-request-item-button-columns-xlarge,
  $info-request-item-button-padding,
  $info-request-item-button-size
);
$info-request-item-button-column-width-large: getInfoRequestButtonGridWidth(
  $info-request-item-button-columns-large,
  $info-request-item-button-padding,
  $info-request-item-button-size
);

:root {
  --border-radius: 0.813rem;
  --navbar-height: 3.125rem;
  --header-height: 7.188rem;
  --main-content-template-padding: 1.094rem;
  --max-with-for-dialog-info: 31.25rem;
  --gap-for-flex: 0.5rem;
  --gap-for-column-grid: 0.625rem;
  --gap-for-row-grid: 0.625rem;
  --global-padding-right: 1.4em;
  --global-padding-left: 1.4em;
  --global-margin-rigth: 0.625rem;
  --global-margin-left: 0.625rem;
  --global-padding-top: 1.25em;
  --global-padding-bottom: 1.25em;
  --mat-menu-item-label-text-weight: 500;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-mdc-form-field-outline-width: 0px;
  --mat-option-hover-state-layer-color: rgb(51 1 1 / 12%);
  --mat-menu-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  --mdc-filled-text-field-label-text-color: var(
    --tertiaryAccentColor
  ) !important;
  --mat-select-trigger-text-size: 15px;
  --mat-select-trigger-text-line-height: 18px;
  --mat-form-field-filled-with-label-container-padding-top: 1.5em;
  --mat-form-field-filled-with-label-container-padding-bottom: 0.3em;
  //--mdc-list-list-item-container-color: var(--primaryAccentColor);
  --mat-select-panel-background-color: var(--appBackground);
  --mdc-filled-text-field-hover-label-text-color: var(
    --tertiaryAccentColor
  ) !important;
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-disabled-label-text-color: var(
    --tertiaryAccentColor
  ) !important;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-handle-width: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-handle-shape: 10px;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms
    cubic-bezier(0, 0, 0.2, 1);
  --mdc-switch-track-width: 36px;
  --mat-switch-track-outline-width: 0;
  --mat-switch-label-text-color: #404040 !important;
  --mat-switch-label-text-font: var(--main-font-family);
  --mat-switch-label-text-line-height: 18px !important;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-weight: 600 !important;
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mat-menu-item-label-text-font: var(--main-font-family);
  --mat-menu-item-label-text-size: 14px;
  --mat-tab-header-active-label-text-color: var(--primaryColor);
  --mat-option-selected-state-label-text-color: var(--primaryColor);
  --mdc-filled-button-disabled-container-color: var(--inactiveButton);
  --mdc-filled-button-disabled-label-text-color: var(--primaryAccentColor);
  --mat-tab-header-active-label-text-color: #404040 !important;
  --mat-tab-header-label-text-font: var(--main-font-family) !important;
  --mat-tab-header-label-text-weight: 400 !important;
}
