@use '@angular/material' as mat;
@use 'src/scss/colors';
@use 'src/scss/fonts';
@use 'src/scss/media-queries';
@use 'node_modules/codemirror/lib/codemirror';
@use 'node_modules/codemirror/theme/material';
@use 'src/scss/variables';
@use './styles-for-material.scss';

@use 'node_modules/quill/dist/quill.core.css' as quill-core;
@use 'node_modules/quill/dist/quill.bubble.css' as quill-bubble;
@use 'node_modules/quill/dist/quill.snow.css' as quill-snow;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
//@include mat.legacy-core();

//$sdrw-dashboard-primary: mat.define-palette(mat.$indigo-palette);
//$sdrw-dashboard-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//$sdrw-dashboard-warn: mat.define-palette(mat.$red-palette);
//$sdrw-dashboard-theme: mat.define-light-theme(
//  (
//    color: (
//      primary: $sdrw-dashboard-primary,
//      accent: $sdrw-dashboard-accent,
//      warn: $sdrw-dashboard-warn,
//    ),
//  )
//);
//@include mat.all-legacy-component-themes($sdrw-dashboard-theme);
$custom-typography: mat.m2-define-legacy-typography-config(
  $input: mat.m2-define-typography-level(inherit, 1.15, 400),
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
//@include mat.all-legacy-component-typographies($custom-typography);

@include mat.all-component-typographies($custom-typography);
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sdrw-ng-web-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$sdrw-ng-web-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$sdrw-ng-web-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sdrw-ng-web-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $sdrw-ng-web-primary,
      accent: $sdrw-ng-web-accent,
      warn: $sdrw-ng-web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-legacy-component-themes($sdrw-ng-web-theme);

@include mat.all-component-themes($sdrw-ng-web-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mat-mdc-progress-spinner circle .main {
  stroke: var(--appBackground);
}

.mat-mdc-progress-spinner circle,
.mat-mdc-spinner circle {
  stroke: var(--primaryColor);
}

.mat-mdc-flat-button.mat-mdc-primary.mat-mdc-button-disabled,
.mat-mdc-flat-button.mat-mdc-accent.mat-mdc-button-disabled,
.mat-mdc-flat-button.mat-mdc-warn.mat-mdc-button-disabled,
.mat-mdc-flat-button.mat-mdc-button-disabled.mat-mdc-button-disabled,
.mat-mdc-raised-button.mat-mdc-primary.mat-mdc-button-disabled,
.mat-mdc-raised-button.mat-mdc-accent.mat-mdc-button-disabled,
.mat-mdc-raised-button.mat-mdc-warn.mat-mdc-button-disabled,
.mat-mdc-raised-button.mat-mdc-button-disabled.mat-mdc-button-disabled,
.mat-mdc-fab.mat-mdc-primary.mat-mdc-button-disabled,
.mat-mdc-fab.mat-mdc-accent.mat-mdc-button-disabled,
.mat-mdc-fab.mat-mdc-warn.mat-mdc-button-disabled,
.mat-mdc-fab.mat-mdc-button-disabled.mat-mdc-button-disabled,
.mat-mdc-mini-fab.mat-mdc-primary.mat-mdc-button-disabled,
.mat-mdc-mini-fab.mat-mdc-mdc-accent.mat-mdc-mdc-button-disabled,
.mat-mdc-mini-fab.mat-mdc-mdc-warn.mat-mdc-mdc-button-disabled,
.mat-mdc-mini-fab.mat-mdc-mdc-button-disabled.mat-mdc-mdc-button-disabled {
  background-color: var(--inactiveButton);
  color: var(--primaryAccentColor);
}

.mat-mdc-form-field-label {
  color: var(--tertiaryAccentColor) !important;
}

.mat-mdc-form-field {
  line-height: 1.2;
}

.mat-mdc-focused .mat-mdc-form-field-label {
  color: var(--primaryColor) !important;
}

.mat-mdc-focused .mat-mdc-form-field-ripple {
  background-color: transparent !important;
}

.mat-mdc-input-invalid .mat-mdc-input-placeholder {
  color: var(--alertsErrors);
}

.mat-mdc-input-invalid .mat-mdc-input-ripple {
  background-color: var(--alertsErrors);
}

.mat-mdc-checkbox-indeterminate.mat-mdc-primary .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked.mat-mdc-primary .mat-mdc-checkbox-background {
  background-color: var(--primaryColor);
}

.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle,
.mat-mdc-radio-button.mat-mdc-accent
  .mat-mdc-radio-ripple
  .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple),
.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked
  .mat-mdc-radio-persistent-ripple,
.mat-mdc-radio-button.mat-mdc-accent:active .mat-mdc-radio-persistent-ripple {
  background-color: transparent;
}

.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked
  .mat-mdc-radio-outer-circle
  .mat-mdc-pseudo-checkbox-checked {
  border-color: var(--primaryColor);
}

.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked
  .mat-mdc-radio-outer-circle {
  border-color: var(--primaryColor);
}

.mat-mdc-primary .mat-mdc-pseudo-checkbox-checked.mat-mdc-pseudo-checkbox-full,
.mat-mdc-primary
  .mat-mdc-pseudo-checkbox-indeterminate.mat-mdc-pseudo-checkbox-full,
.mat-mdc-primary .mat-mdc-pseudo-checkbox-checked {
  background-color: var(--primaryColor);
}

.mat-mdc-calendar-body-selected {
  background-color: var(--primaryColor);
  color: var(--appBackground);
}

.primary-progress-bar .mat-mdc-progress-bar-fill::after {
  background-color: var(--primaryColor) !important;
}

.upload-progress-bar .mat-mdc-progress-bar-fill::after {
  background-color: var(--progressBar) !important;
}

.main-progress-bar .mat-mdc-progress-bar-fill::after {
  background-color: var(--tertiaryAccentColor) !important;
}

.sd-nack-bar,
.mat-mdc-tooltip {
  font-family: var(--main-font-family) !important;
  background-color: var(--primaryAccentColor);
}

.mat-mdc-option,
.mat-mdc-select-value,
.mat-mdc-list-option {
  font-family: var(--main-font-family) !important;
}

.mat-mdc-primary .mat-mdc-option.mat-selected {
  color: var(--primaryColor);
}

.mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar,
.mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar {
  background-color: var(--primaryColor);
}

.cdk-drag-preview {
  background-color: var(--appBackground);
  opacity: 0.7;
}

.mat-mdc-paginator-container {
  font-family: var(--main-font-family) !important;
  color: var(--secondaryAccentColor);
  font-size: 12px !important;
}

.no-padding-dialog .mat-mdc-dialog-container {
  padding: 0;
}
/* NOT WORKING: styles.scss not applied on the browser correctly
.full-screen-dialog {
  width: 100%;
  height: 100%;
  max-width: none !important;
} */

.custom-dialog .mat-mdc-dialog-container {
  padding: 0;
}

.mat-mdc-button {
  min-width: 0 !important;
}

.mat-mdc-menu-panel {
  min-height: 40px !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding: 0 !important;
}

.mat-mdc-tab-label-content {
  font-family: var(--main-font-family);
}

.mat-mdc-slide-toggle.mat-checked:is(.mat-disabled) .mat-mdc-slide-toggle-bar,
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-mdc-slide-toggle-bar {
  background-color: var(--tertiaryAccentColor);
}

.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--primaryColor);
}

.border {
  border-bottom: var(--backgroundShade) 1px solid;
  height: 1px;
  box-sizing: border-box;
}

.spacer {
  flex: 1;
}

.hidden {
  display: none;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.general-content-view {
  padding: 11px 34px;
  max-height: calc(100vh - 22px - 80px);
  max-width: calc(100vw - 68px - 352px);
  overflow: auto;

  app-sd-content-header {
    margin-bottom: 10px;
  }

  .general-content-view-spinner {
    height: calc(100vh - 22px - 80px - 68px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dashboard-dialog {
  background-color: var(--appBackground);
  font-family: var(--main-font-family);
  color: var(--primaryAccentColor);
  border-radius: 14px;

  .dashboard-dialog-header {
    background-color: var(--headerBackground);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 37px;
    height: 93px;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;

    .dashboard-dialog-header-title {
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
    }

    .dashboard-dialog-header-button {
      margin-right: -7px;
    }
  }

  .dashboard-dialog-body {
    padding: 18px 30px;
    max-height: calc(85vh - 70px - 93px);
    max-width: calc(85vw);
    overflow: auto;

    .dashboard-dialog-body-description {
      color: var(--secondaryAccentColor);
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
    }
  }

  .dashboard-dialog-body-spinner {
    height: 300px;
    display: grid;
    place-items: center;
  }

  .dashboard-dialog-footer {
    height: 70px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: var(--webInitialBackground) solid 1px;

    app-sd-flat-button + app-sd-flat-button,
    app-sd-flat-button + app-alert-button {
      padding-left: 14px;
    }
  }
}

.link-text {
  font-family: var(--main-font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 32px;
  color: var(--primaryColor);
  cursor: pointer;
}

.html-cell > * {
  padding: 0 !important;
  margin: 0 !important;
}

.sd-table-secondary-row-text {
  color: var(--tertiaryAccentColor);
  font-size: 13px;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
  .mdc-switch__shadow {
  background-color: var(--primaryColor);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
  .mdc-switch__track::after {
  background-color: var(--tertiaryAccentColor) !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: var(--appBackground) !important;
}

.mdc-switch:enabled .mdc-switch__track::before {
  background-color: var(--tertiaryAccentColor) !important;
}

.mdc-switch:enabled .mdc-switch__shadow {
  box-shadow:
    0 2px 1px -1px #0003,
    0 1px 1px #00000024,
    0 1px 3px #0000001f !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: var(--appBackground) !important;
}

.mat-mdc-tooltip {
  color: var(--appBackground);
  background-color: var(--primaryAccentColor);
  font-family: var(--main-font-family) !important;
  font-size: 75%;
  margin-left: 0.375em;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: 75%;
  border-radius: 3%;
}

.mat-mdc-tooltip-trigger .tooltip-container .ng-star-inserted {
  color: var(--appBackground);
  background-color: var(--primaryAccentColor);
  font-family: var(--main-font-family) !important;
  margin-left: 0.375em;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.font-error {
  font-size: var(--fontSizeForError);
  color: #f44336;
  font-weight: var(--fontWeightForError);
}

.record-upload-snackbar {
  background-color: var(--appBackground);
  height: 4rem !important;
  display: block;
}
.drag-drop-overlay-container-snackbar {
  position: absolute;
}

.mat-mdc-unelevated-button.mdc-button.mat-mdc-button-disabled {
  background-color: var(--inactiveButton) !important;
  color: var(--primaryAccentColor) !important;
}
