@use './scss/media-queries';

@include media-queries.mobile {
  app-sd-flat-button-a11y > button > span.mdc-button__label > div > span {
    white-space: nowrap !important;
  }
}

.mat-mdc-form-field-underline {
  transform: unset !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-progress-spinner circle .main {
  stroke: var(--appBackground) !important;
}

.mat-mdc-progress-spinner circle,
.mat-mdc-spinner circle {
  stroke: var(--primaryColor) !important;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--primaryColor) !important;
  --mdc-linear-progress-track-color: var(--backgroundShade) !important;
}

.mat-mdc-tab:hover {
  background-color: none !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
  color: var(--primaryColor) !important;
}

.mat-mdc-tab-link .mdc-tab-indicator__content--underline {
  border-color: var(--primaryColor) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label--float-above {
  color: var(--primaryColor) !important;
}

.mdc-button {
  min-width: unset !important;
}

.sd-fillable-table
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-top: inherit !important;
  padding-bottom: inherit !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--primaryColor) !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0.125rem !important;
}
.cdk-overlay-container .mat-mdc-select-panel {
  min-width: 100% !important;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--main-font-family);
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 16px !important;
}

.mat-mdc-select {
  font-family: var(--main-font-family) !important;
  font-weight: 500 !important;
}

.mdc-text-field--filled .mdc-floating-label {
  font-family: var(--main-font-family) !important;
}

.mat-mdc-option {
  font-family: var(--main-font-family) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: var(--primaryColor) !important;
}

html .mdc-list-item {
  background-color: transparent;
}

.mat-mdc-primary
  .mat-mdc-pseudo-checkbox-checked.mat-mdc-pseudo-checkbox-minimal::after,
.mat-mdc-primary
  .mat-mdc-pseudo-checkbox-indeterminate.mat-mdc-pseudo-checkbox-minimal::after {
  color: var(--primaryColor) !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: var(--primaryColor) !important;
}

.mat-mdc-tab-link {
  font-family: var(--main-font-family) !important;
}

app-sd-infinite-list .mdc-list-item__primary-text {
  width: 100% !important;
  border-bottom: var(--backgroundShade) solid 1px !important;
  padding: 0rem 0 0.425rem 0 !important;
}

.related-record-autocomplete
  .related-record-option
  .mdc-list-item__primary-text {
  width: 100% !important;
  padding: 0.5em 0 !important;
  margin: 0 0 0.5rem 0 !important;
}

.related-record-autocomplete mat-mdc-option {
  border-bottom: var(--backgroundShade) solid 1px !important;
}

app-sd-infinite-list .related-record-option {
  margin-bottom: 0.5rem !important;
}

.mat-mdc-menu-item {
  min-height: unset !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background {
  border-color: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.sd-link-menu-a11y-mat-menu-panel {
  //width: 21.875rem !important;
  width: 100% !important;
  max-width: unset !important;
  padding-bottom: 0.5rem !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: var(--primaryColor) !important;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primaryColor) !important;
  --mdc-radio-selected-hover-icon-color: var(--primaryColor) !important;
  --mdc-radio-selected-icon-color: var(--primaryColor) !important;
  --mdc-radio-selected-pressed-icon-color: var(--primaryColor) !important;
  --mat-radio-ripple-color: var(--primaryColor) !important;
  --mat-radio-checked-ripple-color: var(--primaryColor) !important;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-snack-bar-container {
  border-radius: var(
    --mdc-snackbar-container-shape,
    var(--mat-sys-corner-extra-small)
  );
  .mdc-snackbar__surface {
    background-color: var(--primaryAccentColor) !important;
  }
}

.mat-mdc-menu-item a {
  line-height: 1rem !important;
}

.mat-mdc-form-field:not(.mat-mdc-form-field-disabled)
  .mat-mdc-floating-label.mdc-floating-label {
  cursor: inherit;
  word-wrap: break-word;
  max-width: 100% !important;
}

.mdc-list-item__primary-text {
  font-size: inherit !important;
  font-family: inherit !important;
  color: inherit !important;
  line-height: inherit !important;
}

.mat-mdc-list-base {
  display: block;
}

.mdc-list {
  padding: 8px 0 0 0 !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: auto !important;
  list-style-type: auto !important;
}

app-plan-field-type-list-item app-sd-time-a11y {
  .label-container {
    position: inherit !important;
  }
  .separator-colon {
    position: relative;
  }
}

.ngx-mat-timepicker {
  &--disabled {
    background-color: transparent !important;
  }
  .period-select {
    width: 5.8em !important;
  }
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.mdc-button--unelevated {
  line-height: 36px !important;
  padding: 0 16px !important;
  border-radius: 4px !important;
}

.mat-mdc-paginator {
  font-size: 12px !important;
}

.mat-mdc-paginator-page-size-select {
  //margin: 6px 4px 0 4px !important;
  width: 56px !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-bottom: 1px solid var(--backgroundShade) !important;
  height: 37px;
}

.mat-mdc-menu-item {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 48px !important;
  height: 48px !important;
  padding: 0 16px !important;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.mat-mdc-menu-panel {
  background: var(--appBackground) !important;
  padding: 0 0.8px;
}

th.mat-mdc-header-cell:first-of-type {
  padding-left: 24px !important;
}

th.mat-mdc-header-cell:last-of-type {
  padding-right: 24px !important;
}

::ng-deep .mat-mdc-paginator-page-size-select .mdc-notched-outline {
  //border: none !important;
  border-bottom: 1px solid var(--backgroundShade) !important;
}

::ng-deep .mat-mdc-paginator-page-size-select .mat-mdc-form-field-flex {
  box-shadow: none !important;
}

::ng-deep .mat-mdc-paginator-page-size-select .mdc-notched-outline__leading,
::ng-deep .mat-mdc-paginator-page-size-select .mdc-notched-outline__trailing {
  display: none !important;
}

.mat-mdc-paginator-page-size-select .mdc-notched-outline {
  //border: none !important;
  border-bottom: 1px solid var(--backgroundShade) !important;
  display: none !important;
}

.mat-mdc-paginator-page-size-select .mat-mdc-select {
  font-size: 12px !important;
}

.mat-mdc-menu-item-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.cdk-overlay-container [aria-labelledby^='mat-paginator-page-size-label'] {
  font-size: 14px;
}

[role='listbox'][aria-labelledby^='mat-paginator-page-size-label'] {
  font-size: 12px;
  opacity: 1;
  min-width: calc(100% + 32px);
  transform: scaleY(1);
  background: var(--appBackground);
}

[role='listbox'] {
  opacity: 1;
  background: var(--appBackground);
}

.cdk-overlay-container
  [aria-labelledby^='mat-paginator-page-size-label']
  .mat-mdc-option {
  font-size: inherit;
  line-height: 3em;
  height: 2.5em;
  min-height: unset !important;
}

.cdk-overlay-container
  [aria-labelledby^='mat-paginator-page-size-label']
  .mat-select-panel
  .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-mdc-body,
.mat-mdc-body-1,
.mat-mdc-typography .mat-mdc-body,
.mat-mdc-typography .mat-mdc-body-1,
.mat-mdc-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--main-font-family);
  letter-spacing: normal;
}

.mdc-text-field {
  padding: 0 !important;
}

// .mat-mdc-form-field-infix {
//   border-top: 0.84375em solid transparent !important;
// }

.mat-mdc-select-trigger {
  height: 1.125em !important;
}

.mat-mdc-form-field-wrapper {
  padding-bottom: 0.25em;
}

.mat-mdc-select-panel .mat-mdc-optgroup-label,
.mat-mdc-select-panel .mat-mdc-option {
  font-size: inherit;
  line-height: 3em;
  height: 3em;
}

.mat-mdc-icon-button {
  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }
}
.mdc-checkbox__background {
  height: 16px !important;
  width: 16px !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
}

.mat-mdc-card {
  box-shadow: var(
    --mdc-elevated-card-container-elevation,
    var(--mat-sys-level1)
  );
}

.mdc-line-ripple::before {
  bottom: 8px;
}

.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-text > *,
.mat-mdc-list-base .mat-mdc-list-option .mat-mdc-list-text > * {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: inherit;
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-left: 8px;
  margin-right: 10px !important;
}

.mdc-list-item__primary-text .request-plan-dialog-body-list-item {
  font-size: 16px !important;
}

button.mat-mdc-unelevated-button.primary:disabled,
button.mat-mdc-unelevated-button[disabled].primary {
  color: var(
    --mdc-filled-button-disabled-label-text-color,
    color-mix(in srgb, var(--mat-sys-on-surface) 38%, transparent)
  ) !important;
  background-color: var(
    --mdc-filled-button-disabled-container-color,
    color-mix(in srgb, var(--mat-sys-on-surface) 12%, transparent)
  ) !important;
  cursor: default;
  pointer-events: none;
}

.mat-mdc-tab {
  font-family: var(--main-font-family) !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
  line-height: 14px !important;
  font-weight: 400 !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: var(--primaryColor) !important;
}

.mat-checkbox .mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple,
.mdc-checkbox__native-control:focus,
.mdc-checkbox__native-control:focus-visible,
.mdc-checkbox:focus-within .mdc-checkbox__ripple {
  display: none !important;
  opacity: 0 !important;
  background: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.cdk-drag.cdk-drag-preview {
  overflow: hidden;
}
