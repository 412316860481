$extra-small: 400px;
$small: 600px;
$medium: 1025px;
$large: 1200px;
$xLarge: 1366px;

$left-menu-expanded-size: 200px;
$left-menu-contracted-size: 80px;

$right-menu-expanded-size: 280px;
$right-menu-contracted-size: 80px;

@mixin extraSmall {
  @media (min-width: #{$extra-small}) {
    @content;
  }
}

@mixin small {
  @media (min-width: #{$small}) {
    @content;
  }
}

@mixin medium {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large}) {
    @content;
  }
}

// NOTE new mobile mixin
@mixin tablet {
  @media (min-width: #{$small}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$small}) {
    @content;
  }
}
